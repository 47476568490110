<template>
  <full-page-gradient>
    <v-layout justify-center class="no-grow">
      <v-flex xs12>
        <typography type="h3">Thank you!</typography>
      </v-flex>
    </v-layout>
    <v-layout class="no-grow" justify-center>
      <v-flex xs12>
        <typography :type="$vuetify.breakpoint.xsOnly ? 'body' : 'h5'" light>
          Nice, we've successfully received your request.
          We are processing your request and will send you an email confirmation to inform you of the outcome in the next 24 hours.
          If you don’t receive this email please contact us on
          <a
            class="white-link"
            href="tel:0800 936 374"
          >0800 Z ENERGY (0800 936 374)</a>
        </typography>
      </v-flex>
    </v-layout>
    <v-layout class="no-grow home-button-container" justify-center>
      <v-flex xs12>
        <round-button
          name="CardPreferencesSuccessHomeButton"
          outline
          color="white"
          @click="onHomeClick"
        >Back to home</round-button>
      </v-flex>
    </v-layout>
  </full-page-gradient>
</template>

<script>
import FullPageGradient from '@/components/templates/FullPageGradient.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { HOME } from '@/constants/routes';

export default {
  components: {
    Typography,
    FullPageGradient,
    RoundButton
  },

  methods: {
    onHomeClick() {
      window.location.href = HOME;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.no-grow {
  flex-grow: 0;
}
.home-button-container {
  padding-top: 35px;
}
.white-link {
  color: #fff;
}
</style>
